import '../scss/home.scss';

function main() {
    let headerBg: HTMLElement | null = document.querySelector('#header-bg');
    if (!headerBg) {
        return;
    }

    let onScroll = () => {
        if (window.scrollY / window.innerHeight < 0.1) {
            headerBg.classList.add('hidden');
        } else {
            headerBg.classList.remove('hidden');
        }
    }

    document.addEventListener('scroll', onScroll);
    onScroll();
}

main();
